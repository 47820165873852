import Vue from 'vue'

const permissions = {
    //Dashboard
    Dashboard: 101,

    //Inventory Management
    InventoryManagement: 201,

    //Invoices
    InvoicesRead     : 301,
    InvoicesWrite    : 302,
    InvoicesReversal : 303,

    //Reporting
    ReportingRead       : 401,
    ReportingWrite      : 402,
    IssuedBillsReversal : 403,

    //General (Zaposleni, Kupci, Načini plačila, DDV, Popusti)
    GeneralRead  : 501,
    GeneralWrite : 502,

    //Products (Izdelki, sestavine, prikazne skupine in urejanje cen)
    ProductsRead  : 601,
    ProductsWrite : 602,
    PricesWrite   : 603,

    //Configuration
    ConfigurationRead  : 701,
    ConfigurationWrite : 702,

    //Pos Confgiuration
    PosConfigurationRead  : 801,
    PosConfigurationWrite : 802,

    //User Configuration
    UserConfigurationRead  : 901,
    UserConfigurationWrite : 902,

    //Deleted
    DeletedRead : 1001
}
Vue.prototype.$permissions = permissions

export default permissions