export default {
  sl: {
    general: {
      profile: 'Profil',
      home: 'Domov',
      second_page: 'Druga stran',
      statistics: 'Statistika',
      no: 'Ne',
      yes: 'Da',
      cancel: 'Prekini',
      admins: 'Administratorji',
      clients: 'Stranke',
      client_users: 'Uporabniki strank',
      tills: 'Blagajne',
      cash_register: 'Nedodane blagajne',
      stock_taking_scanners: 'Skenerji za inventuro',
      cash_register_id_already_exists: 'Blagajna s to oznako že obstaja v tem poslovnem prostoru',
      cash_register_tier_already_added: 'Uporabnik ima paket ESSENTIAL in že dodano blagajno',
      cant_switch_tiers: 'Uporabnik uporablja funkcionalnosti višjega paketa.',
      added_cash_registers: 'Dodane blagajne',
      system_messages: 'Sistemska sporočila',
      issued_bill_update: 'Posodobitev računov iz blagajne',
      resellers: 'Prodajalci'
    },
    login: {
      greeting: 'Dobrodošli!',
      login: 'Prijavi se',
      rememberMe: 'Zapomni si me',
      password: 'Geslo',
      forgottenPassword: 'Pozabljeno geslo?',
      logout: 'Odjavi se',
      wrong_password: 'Geslo je napačno!'
    }
  },
  en: {
    general: {
      profile: 'Profile',
      home: 'Home',
      second_page: 'Second page',
      statistics: 'Statistics',
      no: 'No',
      yes: 'Yes',
      cancel: 'Cancel',
      cash_register_id_already_exists: 'Cash register with specified name already exists in this business room',
      system_messages: 'System messages'
    }
  },
  login: {
    greeting: 'Welcome!',
    login: 'Login',
    rememberMe: 'Remember me',
    password: 'Password',
    forgottenPassword: 'Forgotten password?',
    logout: 'Log out',
    wrong_password: 'Password is not correct!'
  }
}
